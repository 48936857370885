import React, { useState,useEffect,useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars,faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { faCircleXmark } from '@fortawesome/free-regular-svg-icons';
import Banner from '../Assets/Survey_Banner.png';
import Location from '../Assets/Location.png';
import MapGPS from '../components/Map.jsx';
import Plan2 from '../Assets/MapPlan2.png';

const LandingPage = () => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [isZoomed, setIsZoomed] = useState(false);
    const [position, setPosition] = useState({ top: 0, left: 0 });
    const imgRef = useRef(null);
    const [isModalOpen, setIsModalOpen] = useState(false); // ใช้เพื่อเปิด/ปิด modal
    const [location, setLocation] = useState({ name: "", url: "" }); // สถานที่ที่จะนำไปแสดงใน modal
    const [isDragging, setIsDragging] = useState(false);  // เพิ่มสถานะการลาก
    const [dragging, setDragging] = useState(false);
    const [startPosition, setStartPosition] = useState({ x: 0, y: 0 });
  
    const toggleZoom = () => {
        setIsZoomed(!isZoomed);
        setPosition({ left: 0, top: 0 }); // รีเซ็ตตำแหน่งเมื่อออกจากโหมดซูม
      };
    
      const handleDragStart = (x, y) => {
        setDragging(true);
        setStartPosition({ x, y });
      };
    
      const handleDragEnd = () => {
        setDragging(false);
      };
    
      const handleDrag = (clientX, clientY) => {
        if (!isZoomed || !dragging) return;
    
        const deltaX = clientX - startPosition.x;
        const deltaY = clientY - startPosition.y;
    
        // ปรับการเคลื่อนไหวของตำแหน่งแผนที่พร้อมจำกัดขอบเขต
        const newPosition = {
          left: Math.min(Math.max(position.left + deltaX, -window.innerWidth / 2), window.innerWidth / 2),
          top: Math.min(Math.max(position.top + deltaY, -window.innerHeight / 2), window.innerHeight / 2),
        };
    
        setPosition(newPosition);
        setStartPosition({ x: clientX, y: clientY });
      };
    
      const handleMouseMove = (e) => {
        e.preventDefault();
        if (dragging) handleDrag(e.clientX, e.clientY);
      };
    
      const handleTouchMove = (e) => {
        e.preventDefault();
        if (dragging) handleDrag(e.touches[0].clientX, e.touches[0].clientY);
      };

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const handleLocationClick = (e, name, url) => {
        e.preventDefault();  // ป้องกันการกระโดดไปลิงก์โดยตรง
        setLocation({ name, url });
        setIsModalOpen(true);
    };

    // ฟังก์ชันปิด modal
    const closeModal = () => {
        setIsModalOpen(false);
    };
    
    useEffect(() => {
        // ปิดการเลื่อนของ body เมื่อ sidebar เปิดอยู่
        document.body.style.overflow = isSidebarOpen || isZoomed ? 'hidden' : 'auto';
        return () => {
            // คืนค่าให้ body สามารถเลื่อนได้เมื่อ component ถูก unmount
            document.body.style.overflow = 'auto';
        };
    }, [isSidebarOpen,isZoomed]);

    return (
        <>
            <header>
                <nav style={{
                    position: 'fixed',
                    top: 0,
                    width: '100%',
                    background: 'white',
                    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.5)',
                    zIndex: 1000,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    boxSizing: 'border-box',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    padding: '0 20px'
                }}>
                    <div onClick={toggleSidebar} 
                        style={{ 
                            cursor: 'pointer',
                            color: '#B57947',
                            fontSize: '33px',
                        }}>
                        <FontAwesomeIcon icon={isSidebarOpen ? faCircleXmark : faBars} />
                    </div>
                    <div style={{
                        textAlign: 'right',
                        color: '#B57947',
                        fontSize: '20px',
                    }}>
                        <p><strong>
                            ประเพณีลอยกระทง <br /> จังหวัดสุโขทัย ประจำปี 2567
                        </strong></p>
                    </div>
                </nav>
                {isSidebarOpen && (
                    <>
                        <div style={{
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            backgroundColor: '#000000BF', // สีพื้นหลังโปร่งใส
                            zIndex: 998,
                        }} onClick={toggleSidebar}></div> {/* Overlay ที่ปิด Sidebar เมื่อคลิก */}
                        
                        <div style={{
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            height: '100%',
                            width: '80%',
                            background: 'white',
                            boxShadow: '2px 0 5px rgba(0,0,0,0.5)',
                            zIndex: 999,
                            padding: '110px 10px 10px 10px',
                            overflowY: 'scroll',
                            boxSizing: 'border-box'
                        }}>
                            <button style={styles.btnLocation}>
                                <FontAwesomeIcon icon={faLocationDot} style={{ fontSize: '24px', textAlign: 'left' }} />
                                <p style={{
                                    fontSize: '17px',
                                    flexGrow: 1,
                                    textAlign: 'center',
                                    margin: 0,
                                }}>สถานที่จัดกิจกรรม</p>
                            </button>
                            <div style={{ fontSize: '15px', textAlign: 'left' }}>
                                <p>
                                <a 
                                    href="#" 
                                    onClick={(e) => handleLocationClick(e, "โรงละครกลางแจ้ง", "https://www.google.com/maps?q=17.017737,99.70049")}
                                    style={styles.textmap}
                                >
                                    1. โรงละครกลางแจ้ง
                                </a>
                                </p>
                                <p>
                                    <a   
                                        href="#" 
                                        onClick={(e) => handleLocationClick(e, "เวทีบริเวณวัดสระศรี", "https://www.google.com/maps?q=17.017737,99.70049")}
                                        style={styles.textmap}
                                    >
                                        2.เวทีบริเวณวัดสระศรี
                                    </a>
                                </p>
                                <p>
                                    <a 
                                        href="#" 
                                        onClick={(e) => handleLocationClick(e, "บริเวณตระพังตาล", "https://www.google.com/maps?q=17.01676,99.701497")}                                        
                                        style={styles.textmap}>
                                        3.บริเวณตระพังตาล
                                    </a>
                                </p>
                                <p>
                                    <a
                                        href="#" 
                                        onClick={(e) => handleLocationClick(e, "ตลาดบ้านบ้าน ด้านหลังวัดชนะสงคราม", "https://www.google.com/maps?q=17.01676,99.701497")}                                        
                                        style={styles.textmap}
                                    >
                                        4.ตลาดบ้านบ้าน ด้านหลังวัดชนะสงคราม
                                    </a>
                                </p>
                                <p>
                                    <a
                                        href="#" 
                                        onClick={(e) => handleLocationClick(e, "บริเวณสระยายเพิ้ง", "https://www.google.com/maps?q=17.017917,99.701455")}                                        
                                        style={styles.textmap}
                                    >                         
                                         5.บริเวณสระยายเพิ้ง
                                    </a>
                                </p>
                                <p>
                                    <a
                                        href="#" 
                                        onClick={(e) => handleLocationClick(e, "ตระพังตะกวน", "https://www.google.com/maps?q=17.018916,99.701463")}                                        
                                        style={styles.textmap}
                                    >                                         
                                        6.ตระพังตะกวน
                                    </a>
                                </p>
                                <p>
                                    <a
                                        href="#" 
                                        onClick={(e) => handleLocationClick(e, "ตระพังตาล ตรงข้ามวัดสระศรี", "https://www.google.com/maps?q=17.018816,99.701896")}                                        
                                        style={styles.textmap}
                                    >                                            
                                        7.ตระพังตาล ตรงข้ามวัดสระศรี
                                    </a>
                                </p>
                                <p>
                                    <a
                                        href="#" 
                                        onClick={(e) => handleLocationClick(e, "พระบรมหาราชานุสาวรียฟ์พ่อขุนรามคำแหงมหาราช", "https://www.google.com/maps?q=17.018661,99.704841")}                                        
                                        style={styles.textmap}
                                    >                                         
                                        8.พระบรมหาราชานุสาวรียฟ์พ่อขุนรามคำแหงมหาราช
                                    </a>
                                </p>
                                <p>
                                    <a
                                        href="#" 
                                        onClick={(e) => handleLocationClick(e, "แพลอยกระทงใหญ่ สระตระพังตาล", "https://www.google.com/maps?q=17.018816,99.701896")}                                        
                                        style={styles.textmap}
                                    >                                        
                                        9.แพลอยกระทงใหญ่ สระตระพังตาล
                                    </a>
                                </p>
                                <p>
                                    <a
                                        href="#" 
                                        onClick={(e) => handleLocationClick(e, "ตรอกช่างทอง ชุมชนสุโขทัย", "https://www.google.com/maps?q=17.018418,99.70498")}                                        
                                        style={styles.textmap}
                                    >                                          
                                        10. ตรอกช่างทอง ชุมชนสุโขทัย
                                    </a>
                                </p>
                                <p>
                                    <a
                                        href="#" 
                                        onClick={(e) => handleLocationClick(e, "บริเวณด้านหลังวัดชนะสงคราม", "https://www.google.com/maps?q=17.018087,99.702398")}                                        
                                        style={styles.textmap}
                                    >                                           
                                        11.บริเวณด้านหลังวัดชนะสงคราม
                                    </a>
                                </p>
                                <p>
                                    <a
                                        href="#" 
                                        onClick={(e) => handleLocationClick(e, "บริเวณศาลาสี่หลัง", "https://www.google.com/maps?q=17.018328,99.702477")}                                        
                                        style={styles.textmap}
                                    >                                        
                                        12.บริเวณศาลาสี่หลัง
                                    </a>
                                </p>
                                <p>
                                    <a
                                        href="#" 
                                        onClick={(e) => handleLocationClick(e, "ศาลาป่ามะม่วงด้านหลังวัดมหาธาตุ", "https://www.google.com/maps?q=17.018121,99.702349")}                                        
                                        style={styles.textmap}
                                    >
                                        13.ศาลาป่ามะม่วงด้านหลังวัดมหาธาตุ
                                    </a>
                                </p>
                                <p>
                                    <a
                                        href="#" 
                                        onClick={(e) => handleLocationClick(e, "บริเวณด้านข้างสระยายเพิ้ง", "https://www.google.com/maps?q=17.017762,99.702493")}                                        
                                        style={styles.textmap}
                                    >                                        
                                        14.บริเวณด้านข้างสระยายเพิ้ง
                                    </a>
                                </p>
                                <p>
                                    <a
                                        href="#" 
                                        onClick={(e) => handleLocationClick(e, "วัดมหาธาตุ", "https://www.google.com/maps?q=17.018218,99.702766")}                                        
                                        style={styles.textmap}
                                    >                                        
                                        15.วัดมหาธาตุ
                                    </a>
                                </p>
                                <p>
                                    <a
                                        href="#" 
                                        onClick={(e) => handleLocationClick(e, "ด้านหน้าวัดชนะสงคราม", "https://www.google.com/maps?q=17.019631,99.704733")}                                        
                                        style={styles.textmap}
                                    >                                        
                                        16.ด้านหน้าวัดชนะสงคราม
                                    </a>
                                </p>
                                <p>
                                    <a
                                        href="#" 
                                        onClick={(e) => handleLocationClick(e, "เวทีครึ่งวงกลม ด้านหน้าวัดมหาธาตุ", "https://www.google.com/maps?q=17.01882,99.704988")}                                        
                                        style={styles.textmap}
                                    >                                        
                                        17.เวทีครึ่งวงกลม ด้านหน้าวัดมหาธาตุ
                                    </a>
                                </p>
                                <p>
                                    <a
                                        href="#" 
                                        onClick={(e) => handleLocationClick(e, "บริเวณด้านข้างดงตาล", "https://www.google.com/maps?q=17.0171050567345,99.70446268197479")}                                        
                                        style={styles.textmap}
                                    >                                        
                                        18.บริเวณด้านข้างดงตาล
                                    </a>
                                </p>
                                <p>
                                    <a
                                        href="#" 
                                        onClick={(e) => handleLocationClick(e, "เวทีบริเวณด้านข้างวัดชนะสงคราม", "https://www.google.com/maps?q=17.01882,99.704988")}                                        
                                        style={styles.textmap}
                                    >                                        
                                        19.เวทีบริเวณด้านข้างวัดชนะสงคราม
                                    </a>
                                </p>
                                <p>
                                    <a
                                        href="#" 
                                        onClick={(e) => handleLocationClick(e, "ลานด้านข้างวัดใหม่", "https://www.google.com/maps?q=17.018599,99.706614")}                                        
                                        style={styles.textmap}
                                    >                                        
                                        20.ลานด้านข้างวัดใหม่
                                    </a>
                                </p>
                            </div>
                        </div>
                    </>
                )}
            </header>
            <main>
                <div style={styles.container}>
                    <div>
                            <img src={Banner} alt="Banner" style={styles.Img} />
                    </div>
                    <div>
                        <h1 style={{ color: '#B57947',fontSize:'4vw',fontWeight:'bold' }}>แผนผังจัดงาน</h1>
                        <div style={{ position: 'relative' }}>
                            <img 
                                src={Plan2} 
                                alt="Plan" 
                                style={styles.imgPlan}
                                onClick={toggleZoom} 
                            />
                             {isZoomed && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
            zIndex: 1002,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          onClick={toggleZoom}  // เมื่อคลิกปิดโหมดซูม
        >
          <MapGPS
            onMouseDown={(e) => handleDragStart(e.clientX, e.clientY)}
            onMouseUp={handleDragEnd}
            onMouseMove={handleMouseMove}
            onTouchStart={(e) => handleDragStart(e.touches[0].clientX, e.touches[0].clientY)}
            onTouchEnd={handleDragEnd}
            onTouchMove={handleTouchMove}
            style={{
              maxWidth: 'none',
              maxHeight: 'none',
              transform: `translate(${position.left}px, ${position.top}px) scale(2.5)`,
              transition: dragging ? 'none' : 'transform 0.3s ease',
              cursor: dragging ? 'grabbing' : 'grab',
            }}
          />
        </div>
      )}
                        </div>
                    </div>                    
                    <div>
                        <img src={Location} alt="Location" style={styles.Img}/>
                    </div>
                </div>
                {isModalOpen && (
                    <div style={{
                        position: "fixed",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        backgroundColor: "rgba(0, 0, 0, 0.7)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        zIndex: 1000
                    }}>
                        <div style={{
                            background: "white",
                            padding: "20px",
                            borderRadius: "8px",
                            textAlign: "center",
                            width: "auto",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                        }}>
                            <h3 style={{
                                margin:'10px 0',
                                color:'#B57947',
                                fontWeight:'bold',
                                fontSize:'25px'
                            }}>ท่านต้องการเดินทางไป</h3>
                            <h4 style={{
                                    margin:'10px 0',
                                    color:'#B57947',
                                    fontSize:'17px',
                                }}>{location.name}</h4>

                            <div style={{
                                width: '100%',
                                marginBottom: '10px',
                            }}>
                                <a href={location.url} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                                    <button style={{
                                        backgroundColor: '#B57947',
                                        color: 'white',
                                        padding: '10px 20px',
                                        border: 'none',
                                        borderRadius: '5px',
                                        cursor: 'pointer',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        gap: '8px',
                                        width: '100%',
                                        textAlign: 'center',
                                        fontSize:'15px',
                                    }}>
                                        <FontAwesomeIcon icon={faLocationDot} style={{ fontSize: '24px' }} />
                                        <p style={{ margin: 0 }}>นำทางไปสถานที่จัดกิจกรรม</p>
                                    </button>
                                </a>
                            </div>

                            <div style={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginTop: '10px',
                            }}>
                                <button onClick={closeModal} style={{
                                    backgroundColor: '#B57947',
                                    color: 'white',
                                    padding: '10px 20px',
                                    border: 'none',
                                    borderRadius: '5px',
                                    cursor: 'pointer',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    gap: '8px',
                                    width: '100%',
                                    textAlign: 'center',
                                    fontSize:'15px',
                                }}>
                                    <p style={{ margin: 0 }}>ปิดหน้าต่าง</p>
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </main>
        </>
    );
};
const styles = {
    container:{
        paddingTop:'90px',
    },
    Img: {
        width: '100%',
        height: 'auto',
    },
    imgPlan:{
        width: '90%',
        height: 'auto',
        cursor:'pointer'
    },
    btnLocation: {
        display: 'flex',
        gap: '8px',
        padding: '12px',
        borderRadius: '8px',
        background: '#B57947',
        color: 'white',
        border: 'none',
        width: '100%',
        fontWeight: 'bold',
        cursor: 'pointer',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
        alignItems:'center'
    },
    textmap:{
        color: '#B57947',
        textDecoration: 'none'
    }    
}

export default LandingPage;
