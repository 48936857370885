import React, { useEffect, useState } from "react";
import Mapgps from '../Assets/MapPlan.png';

const Map = () => {
  const [userPosition, setUserPosition] = useState(null);
  const [nearbyPins, setNearbyPins] = useState([]);

  const pins = [
    { id: 1, top: "10%", left: "15%", lat: 13.677918360540962, lng: 100.43822196212693, url: "https://www.google.com/maps?q=13.677918360540962,100.43822196212693" },
    { id: 2, top: "5%", left: "15%", lat: 13.677612311195533, lng: 100.43821163182452, url: "https://www.google.com/maps?q=13.677612311195533, 100.43821163182452"},
    // เพิ่มหมุดอื่น ๆ ตามที่ต้องการ
  ];

const calculateDistance = (lat1, lng1, lat2, lng2) => {
  const toRadians = (degree) => degree * (Math.PI / 180);
  const R = 6371; // ระยะรัศมีของโลกในกิโลเมตร
  const dLat = toRadians(lat2 - lat1);
  const dLng = toRadians(lng2 - lng1);

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRadians(lat1)) * Math.cos(toRadians(lat2)) *
    Math.sin(dLng / 2) * Math.sin(dLng / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = R * c * 1000; // ผลลัพธ์จะเป็นระยะทางในเมตร
  return distance;
};

  useEffect(() => {
    let interval;
    const handleVisibilityChange = () => {
      if (document.hidden) {
        // ถ้าอยู่ใน background, หยุดการอัปเดตตำแหน่ง
        clearInterval(interval);
      } else {
        // ถ้าอยู่ใน foreground, เริ่มการอัปเดตตำแหน่ง
        updatePosition();
        interval = setInterval(updatePosition, 5000); // อัปเดตทุก 5 วินาที
      }
    };

    const updatePosition = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            setUserPosition({ lat: latitude, lng: longitude });

            // คำนวณระยะห่างจากตำแหน่งผู้ใช้ไปยังหมุดทุกตัว
            const nearby = pins.filter((pin) => {
              const distance = calculateDistance(latitude, longitude, pin.lat, pin.lng);
              return distance < 1; // เปลี่ยนระยะห่างที่ต้องการ เช่น 1000 เมตร (1 กิโลเมตร)
            });

            setNearbyPins(nearby);
          },
          (error) => {
            console.error("Geolocation error:", error);
            alert("ไม่สามารถเข้าถึงตำแหน่งได้"); // แจ้งผู้ใช้เมื่อไม่สามารถรับตำแหน่ง
          },
          { enableHighAccuracy: true }
        );
      }
    };

    // ตรวจสอบสถานะการซ่อน/แสดงหน้าเว็บ
    document.addEventListener('visibilitychange', handleVisibilityChange);

    // เริ่มต้นการอัปเดตตำแหน่งหากอยู่ใน foreground
    if (!document.hidden) {
      updatePosition();
      interval = setInterval(updatePosition, 5000);
    }

    // เคลียร์ interval เมื่อ component ถูกทำลาย
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      clearInterval(interval);
    };
  }, []);

  const mapContainerStyle = {
    position: "relative",
    width: "100%", // ขนาดแผนที่คงที่
    height: "auto", // สูงจะปรับตามความกว้าง
    overflow: "hidden",
    cursor: "default",
  };

  const mapImageStyle = {
    width: "100%",
  };

  const pinStyle = (pin) => ({
    position: "absolute",
    backgroundColor: nearbyPins.some((nearPin) => nearPin.id === pin.id) ? "green" : "red", // เปลี่ยนเป็นสีฟ้าถ้าอยู่ใกล้
    width: "20px",
    height: "20px",
    borderRadius: "50%",
    color: "white",
    textAlign: "center",
    lineHeight: "20px",
    fontSize: "12px",
    cursor: "pointer",
    textDecoration: "none",
  });

  return (
    <div style={mapContainerStyle}>
      <img src={Mapgps} alt="Map" style={mapImageStyle} />
      {pins.map((pin) => (
        <a
          key={pin.id}
          href={pin.url}
          target="_blank"
          rel="noopener noreferrer"
          style={{ ...pinStyle(pin), top: pin.top, left: pin.left }}
        >
          {pin.id}
        </a>
      ))}
    </div>
  );
};

export default Map;
