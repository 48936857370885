import logo from './logo.svg';
import './App.css';
import LandingPage from './Pages/LandingPage';

import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';

function App() {
  const HOMEPAGE = process.env.REACT_APP_HOMEPAGE;
  const IS_DEV = parseInt(process.env.REACT_APP_IS_DEV);
  
  var basename = IS_DEV ? '/' : HOMEPAGE;
  return (
    <div className="App">
      <Router basename={basename}>
          <Routes>
            <Route path="/" element={<LandingPage />} />
          </Routes>
      </Router>
    </div>
  );
}

export default App;
